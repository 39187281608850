(function (document, window, $, gtag) {
    document.addEventListener('wpcf7mailsent', function (event) {
        const id = parseInt(event.detail.contactFormId);
        if (id === 107) { // contact us form
            gtag('event', 'conversion', { 'send_to': 'AW-781414121/T6CsCMGX-osBEOndzfQC' });
        }
    }, false);

    $(window).on('scroll', function () {
        if ($(window).scrollTop() > 0) {
            $('header.site').addClass('shadow');
        } else {
            $('header.site').removeClass('shadow');
        }
    });
    const percentDiv = $('.home-6 .percent');
    const percentSector = $('.home-6 .sector');
    let percentInterval = null;
    let currentPercent = 0;
    let targetPercent = 0;
    $('.home-6 input[type="checkbox"]').change(function () {
        const checked = $('.home-6 input[type="checkbox"]:checked').length;
        if (percentInterval) clearInterval(percentInterval);
        targetPercent = checked * 100 / 4;
        percentInterval = setInterval(animatePercent, 1000 / 25);
    });
    function animatePercent() {
        if (currentPercent < targetPercent) {
            ++currentPercent;
        } else if (currentPercent > targetPercent) {
            --currentPercent;
        }
        percentDiv.text(currentPercent.toString() + '%');
        let deg = currentPercent * 3.6;
        if (deg <= 180) {
            percentSector.removeClass('bg-primary');
            percentSector.css('transform', 'rotate(' + deg.toString() + 'deg)');
        } else {
            deg -= 180;
            percentSector.addClass('bg-primary');
            percentSector.css('transform', 'rotate(' + deg.toString() + 'deg)');
        }
        if (currentPercent === targetPercent) {
            clearInterval(percentInterval);
        }
    }
    function animateLogo() {
        const logos = $('.hiw-4 .logo');
        const current = logos.filter('.active');
        var index = logos.index(current);
        index = (index + 1) % 5;
        current.removeClass('active');
        logos.eq(index).addClass('active');
    }
    if ($('.hiw-4').length) {
        setInterval(animateLogo, 1000);
    }
    const dragHint = $('.drag-hint');
    if (dragHint.length) {
        let pos = $('#input-loan-amount').position();
        dragHint.css(pos);
        dragHint.addClass('start');
        setTimeout(function () {
            pos = $('#input-repayment').position();
            dragHint.css(pos);
            dragHint.removeClass('start');
            setTimeout(function () {
                dragHint.addClass('start');
                setTimeout(function () {
                    dragHint.css({ display: 'none' });
                }, 2000);
            }, 100);
        }, 2000);
    }

    $('body').on('submit', '#application-form', function (evt) {
        evt.preventDefault();
        gtag('event', 'conversion', { 'send_to': 'AW-781414121/BwiuCO3Wg40BEOndzfQC' });
        let url = 'https://apply.cashpal.com.au/cp/start?source=' + encodeURIComponent(window.source);
        url += '&loan_amount=' + $('#input-loan-amount').val();
        url += '&loan_term=' + $('#input-repayment').val();
        window.location = url;
    });
})(document, window, jQuery, gtag);